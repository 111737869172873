import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = () => {
    let valid = true;
    let errors = { email: "", password: "" };

    if (!email) {
      errors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(email)) {
      errors.email = "Enter a valid email address";
      valid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      valid = false;
    } else if (password.length < 6) {
      errors.password = "Password should be at least 6 characters";
      valid = false;
    }

    if (email !== "admin@technoduces.com" || password !== "123456") {
      errors.email = "Invalid email or password";
      valid = false;
    }

    setErrors(errors);

    if (valid) {
      localStorage.setItem("isAuthenticated", "true");
      navigate("/dashboard");
    }
  };

  return (
    <Box
      className="flex w-full justify-center min-h-screen bg-white items-center"
      sx={{
        background:
          "url(https://images.pexels.com/photos/302769/pexels-photo-302769.jpeg) no-repeat center",
        backgroundSize: "cover",
      }}
    >
      <Box
        className="flex justify-center items-center p-10"
        sx={{
          width: "500px",
          background: "rgb(255 255 255 / 85%)",
          boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 41%)",
          backdropFilter: "blur(20px)",
          borderRadius: "10px",
        }}
      >
        <Box className="flex flex-col gap-6 w-full py-5">
          <Box className="text-center">
            <h3 className="text-2xl font-bold mb-4">Login</h3>
            <p className="opacity-70">
              Please sign-in to your account and start the adventure
            </p>
          </Box>
          <TextField
            label="Email Id"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Password"
            fullWidth
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Box>
            <Button
              variant="contained"
              disableElevation
              onClick={handleLogin}
              sx={{
                minHeight: "50px",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
              fullWidth
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
