import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();
  return (
    <div className="text-center flex flex-col min-h-screen justify-center items-center">
      <div className="mb-10">
        <img src="/images/404.png" alt="something went wrong" />
      </div>
      <h3 className="font-bold text-2xl mb-2">Something Went wrong</h3>
      <p className="mb-6 opacity-70">
        Brace yourself till we get the error fixed. <br />
        You may also refresh the page or try again later{" "}
      </p>
      <Button variant="contained" disableElevation onClick={() => navigate(-1)}>
        Back to Home
      </Button>
    </div>
  );
};

export default Dashboard;
